import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchRecipeById, shareRecipe, deleteRecipe } from "../API/api";
import { AuthContext } from "../AuthContext";
import "../CSS/site.css";

const RecipeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState(null);
  const { user } = useContext(AuthContext);
  const [isCookModeOn, setIsCookModeOn] = useState(false);

  useEffect(() => {
    const loadRecipe = async () => {
      try {
        const data = await fetchRecipeById(id);

        console.log("Recept data:", data);
        console.log("Gebruiker data:", user);

        const hasAccess =
          data.isPublic ||
          (user &&
            (data.author === user.username ||
              data.sharedWith.some(
                (sharedUser) =>
                  sharedUser._id === user.id || sharedUser.email === user.email
              )));

        console.log("Heeft toegang:", hasAccess);

        if (hasAccess) {
          setRecipe(data);
        } else {
          navigate("/access-denied");
        }
      } catch (error) {
        console.error("Fout bij ophalen van recept:", error);
        navigate("/access-denied");
      }
    };

    loadRecipe();
  }, [id, navigate, user]);

  useEffect(() => {
    let wakeLock = null;

    const enableCookMode = async () => {
      try {
        wakeLock = await navigator.wakeLock.request("screen");
        console.log("Kookstand is actief!");
      } catch (err) {
        console.error("Kan kookstand niet activeren:", err);
      }
    };

    const disableCookMode = () => {
      if (wakeLock) {
        wakeLock.release();
        wakeLock = null;
        console.log("Kookstand is gedeactiveerd.");
      }
    };

    if (isCookModeOn) {
      enableCookMode();
    } else {
      disableCookMode();
    }

    return () => {
      if (wakeLock) {
        disableCookMode();
      }
    };
  }, [isCookModeOn]);

  const handleAuthorClick = () => {
    navigate(`/recipes?author=${recipe.author}`);
  };

  const handleShare = async () => {
    if (!user || user.username !== recipe.author) {
      Swal.fire(
        "Niet toegestaan",
        "Alleen de eigenaar kan dit recept delen.",
        "error"
      );
      return;
    }

    const tempSharedWith = [...recipe.sharedWith]; // Tijdelijke kopie van gedeelde gebruikers

    const { value: formValues } = await Swal.fire({
      html: `
    <div class="share-toggle-container">
      <label class="share-switch">
        <input type="checkbox" id="public-toggle" ${
          recipe.isPublic ? "checked" : ""
        } /> 
        <span class="slider"></span>
      </label>
        <span id="public-status">
          <span>${recipe.isPublic ? "Openbaar" : "Privé"}</span>
        </span>
      </div>
      <div class="shared-container">
        <div>Gedeeld met:</div>
        <ul id="shared-with-list" style="list-style: none; padding: 0;">
          ${tempSharedWith
            .map(
              (sharedUser, index) => `
              <li class="shared-email-pill" data-index="${index}">
                ${sharedUser.email}
                <button 
                  class="remove-tag-button" 
                  data-index="${index}"
                  style="margin-left: 10px; background: transparent; border: none; color: red; cursor: pointer;"
                >
                  &times;
                </button>
              </li>
            `
            )
            .join("")}
        </ul>
        <textarea 
          class="share-email-textarea"
          id="new-emails" 
          placeholder="Voeg nieuwe e-mailadressen toe, gescheiden door komma's..." 
          style="width: 100%; height: 100px;"
        ></textarea>
      </div>
    <textarea 
      class="share-email-textarea"
      id="custom-message" 
      placeholder="Voeg een bericht toe (optioneel)..."
      style="width: 100%; height: 80px;"></textarea>
  `,
      confirmButtonText: "Opslaan",
      confirmButtonColor: "#c43402",
      showCancelButton: true,
      cancelButtonText: "Annuleren",
      cancelButtonColor: "#c43402",
      scrollbarPadding: false,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
        confirmButton: "custom-swal-button",
        cancelButton: "custom-swal-button",
      },
      didOpen: () => {
        // Toggle voor openbaar/privé
        const toggle = document.getElementById("public-toggle");
        const statusSpan = document.getElementById("public-status");
        toggle.addEventListener("change", (event) => {
          const isPublic = event.target.checked;
          statusSpan.textContent = isPublic ? "Openbaar" : "Privé";
        });

        // Event listeners voor verwijderen
        document.querySelectorAll(".remove-tag-button").forEach((button) => {
          button.addEventListener("click", (e) => {
            const index = e.target.getAttribute("data-index");
            tempSharedWith.splice(index, 1); // Verwijder gebruiker uit tijdelijke lijst
            e.target.parentElement.remove(); // Verwijder visueel
          });
        });
      },
      preConfirm: () => {
        const isPublic = document.getElementById("public-toggle").checked;
        const newEmails = document
          .getElementById("new-emails")
          .value.split(",")
          .map((email) => email.trim())
          .filter(Boolean);

        const customMessage = document
          .getElementById("custom-message")
          .value.trim();

        return {
          isPublic,
          emails: [
            ...tempSharedWith.map((user) => user.email), // Gebruikers uit de tijdelijke lijst
            ...newEmails, // Nieuwe e-mails
          ],
          message: customMessage,
        };
      },
    });

    if (formValues) {
      try {
        await shareRecipe(recipe._id, formValues);
        setRecipe((prev) => ({
          ...prev,
          isPublic: formValues.isPublic,
          sharedWith: formValues.emails.map((email) => ({ email })), // Update sharedWith
        }));
        Swal.fire({
          title: "Succes!",
          text: "Delen is bijgewerkt.",
          icon: "success",
          scrollbarPadding: false,
        });
      } catch (error) {
        console.error("Fout bij het bijwerken van het recept:", error);
        Swal.fire({
          title: "Fout",
          text: "Recept kon niet worden bijgewerkt.",
          icon: "error",
        });
      }
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "Weet je het zeker?",
      text: "Dit recept zal permanent worden verwijderd.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#c43402",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, verwijderen",
      cancelButtonText: "Annuleren",
      scrollbarPadding: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Verwijder het recept via de API
          await deleteRecipe(id);
          Swal.fire("Verwijderd!", "Het recept is verwijderd.", "success");
          navigate("/"); // Navigeer terug naar de homepage
        } catch (error) {
          console.error("Fout bij het verwijderen van het recept:", error);
          Swal.fire(
            "Mislukt",
            "Er is een fout opgetreden bij het verwijderen van het recept.",
            "error"
          );
        }
      }
    });
  };

  if (!recipe) return <p>Recept wordt geladen...</p>;

  return (
    <div className="page">
      <div className="recipe-detail-container">
        <div className="recipe-title">
          <div className="title-and-buttons">
            <div className="recipe-title-text">{recipe.title}</div>
            <div className="recipe-actions">
              <button onClick={() => navigate(-1)} className="material-icons">
                arrow_back
              </button>
              {user && user.username === recipe.author && (
                <button
                  onClick={() => navigate(`/edit/${id}`)}
                  className="material-icons"
                >
                  edit
                </button>
              )}
              {user && user.username === recipe.author && (
                <button onClick={handleShare} className="material-icons">
                  share
                </button>
              )}
              {user && user.username === recipe.author && (
                <button onClick={handleDelete} className="material-icons">
                  delete
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="toggle-container">
          <label className="switch">
            <input
              type="checkbox"
              checked={isCookModeOn}
              onChange={() => setIsCookModeOn(!isCookModeOn)}
            />
            <span className="slider"></span>
          </label>
          <span id="toggle-label">
            {isCookModeOn ? "Kookstand Aan" : "Kookstand Uit"}
          </span>
        </div>

        <div className="recipe-content-layout">
          <div className="recipe-left-column">
            <div className="recipe-image-container">
              {recipe.photo ? (
                <img
                  src={recipe.photo}
                  alt={recipe.title}
                  className="recipe-image"
                />
              ) : (
                <div className="placeholder-image">
                  <p>Geen afbeelding beschikbaar</p>
                </div>
              )}
            </div>
            <div className="recipe-servings">
              Aantal personen: {recipe.servings}
            </div>

            <div className="recipe-ingredients">
              <h2>Ingrediënten</h2>
              {recipe.ingredients && recipe.ingredients.length > 0 ? (
                <ul className="ingredient-list">
                  {recipe.ingredients.map((ingredient, index) => (
                    <div key={index} className="ingredient-item-container">
                      <li className="ingredient-item">{ingredient}</li>
                    </div>
                  ))}
                </ul>
              ) : (
                <p>Geen ingrediënten beschikbaar.</p>
              )}
            </div>
          </div>
          <div className="recipe-instructions">
            <span
              className="recipe-author"
              onClick={handleAuthorClick}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Toegevoegd door: {recipe.author}
            </span>
            <h2>Instructies</h2>
            <div dangerouslySetInnerHTML={{ __html: recipe.instructions }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeDetails;
