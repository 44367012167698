import React from "react";
import { Link } from "react-router-dom";
import "../CSS/site.css";

const AccessDenied = () => {
  return (
    <div className="page">
      <div className="access-denied-container">
        <h1>Geen Toegang</h1>
        <p>Je hebt geen toegang tot dit recept.</p>
        <Link to="/" className="back-home-button">
          Terug naar Home
        </Link>
      </div>
    </div>
  );
};

export default AccessDenied;
