import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Recept uploaden
export const uploadRecipe = async (formData) => {
  try {
    // console.log("Starting uploadRecipe function...");

    const token = localStorage.getItem("token");
    // console.log("Token retrieved:", token);

    if (!token) {
      console.error("No token found in localStorage.");
      throw new Error("Authentication token not found.");
    }

    // Controleren of formData een FormData object is
    if (!(formData instanceof FormData)) {
      console.error("The provided data is not a FormData object.");
      throw new Error(
        "Expected FormData instance, but received something else."
      );
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data", // Zorg ervoor dat de content type goed is ingesteld
      },
    };

    // Bepaal URL en methode voor upload of update
    const id = formData.get("_id"); // Controleer of het ID correct is toegevoegd
    const url = id
      ? `${API_URL}/recipes/edit/${id}`
      : `${API_URL}/recipes/upload`;
    const method = id ? "put" : "post";

    // console.log(`Verstuur ${method.toUpperCase()} verzoek naar URL: ${url}`);

    // Verstuur het verzoek via axios
    const response = await axios[method](url, formData, config);
    // console.log("Response ontvangen:", response.data);

    return response.data;
  } catch (error) {
    console.error("Fout tijdens recept uploaden:", error);
    if (error.response) {
      console.error("Fout respons data:", error.response.data);
    }
    throw error;
  }
};

// Recepten ophalen

export const fetchRecipes = async (filters = {}) => {
  try {
    const response = await axios.get(`${API_URL}/recipes`, {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recipes:", error);
    return [];
  }
};

export const getRecentRecipes = async () => {
  const filters = {
    sort: "-date", // Sorteer op datum in aflopende volgorde (recentste eerst)
    limit: 5, // Beperk het aantal resultaten tot 5
  };
  return await fetchRecipes(filters);
};

export const shareRecipe = async (recipeId, data) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${API_URL}/recipes/share`,
      { recipeId, ...data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Fout bij het delen van recept:", error);
    throw error;
  }
};

// 1 Recept ophalen
export const fetchRecipeById = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${API_URL}/recipes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Fout bij ophalen van recept:", error);
    throw error;
  }
};

export const deleteRecipe = async (id) => {
  const token = localStorage.getItem("token");
  try {
    await axios.delete(`${API_URL}/recipes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Error deleting recipe:", error);
    throw error;
  }
};

// Function to register a new user
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, userData);
    return response.data;
  } catch (error) {
    console.error("Error registering user:", error);
    throw error;
  }
};

// Function to verify email confirmation (if needed)
export const confirmEmail = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/auth/confirm/${token}`);
    return response.data;
  } catch (error) {
    console.error("Error confirming email:", error);
    throw error;
  }
};

export const checkAvailability = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/auth/check-availability`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error checking availability:", error);
    throw error;
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, loginData);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

// Functie om een wachtwoord-reset aanvraag te doen
export const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/auth/forgot-password`, {
      email,
    });
    return response.data; // Geeft een succesbericht terug
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axios.post(
      `${API_URL}/auth/reset-password/${token}`,
      {
        password: newPassword,
      }
    );
    return response.data; // Geeft een succesbericht terug
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};
