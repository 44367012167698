import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../API/api";
import "../CSS/site.css";

const ResetPasswordForm = () => {
  const { token } = useParams(); // Haal de token op uit de URL
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Wachtwoorden komen niet overeen.");
      return;
    }

    try {
      await resetPassword(token, password);
      setSuccess(true);
      setError(null);
      setTimeout(() => navigate("/login")); // Navigeer terug naar login na succes
    } catch (err) {
      setError("Fout bij het resetten van wachtwoord. Probeer opnieuw.");
    }
  };

  return (
    <div className="page">
      <div className="password-reset-container">
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success ? (
          <p style={{ color: "green" }}>Wachtwoord succesvol gereset!</p>
        ) : (
          <form onSubmit={handleSubmit} className="reset-password-form">
            <div className="form-row">
              <label>Nieuw Wachtwoord:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="tag-input"
              />
            </div>
            <div className="form-row">
              <label>Bevestig Wachtwoord:</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="tag-input"
              />
            </div>
            <div className="form-button-row">
              <button type="submit">Reset Wachtwoord</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
