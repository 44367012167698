import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { loginUser, requestPasswordReset } from "../API/api";
import { AuthContext } from "../AuthContext";
import "../CSS/site.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      Swal.fire({
        icon: "error",
        title: "Fout",
        text: "Vul zowel een e-mailadres als een wachtwoord in.",
        confirmButtonColor: "#c43402",
      });
      return;
    }

    try {
      const { token, username, id } = await loginUser({ email, password });
      login(token, username, id);

      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get("redirect") || "/";
      Swal.fire({
        icon: "success",
        title: "Succes",
        text: "Je bent ingelogd!",
        confirmButtonColor: "#c43402",
        timer: 2000,
        showConfirmButton: false,
      }).then(() => {
        navigate(redirectUrl);
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Fout",
        text: "Inloggen mislukt. Controleer uw gegevens.",
        confirmButtonColor: "#c43402",
      });
    }
  };

  const handleForgotPassword = () => {
    Swal.fire({
      title: "Reset Aanvragen",
      html: `
        <input type="email" id="forgot-password-email" class="swal2-input" placeholder="Voer je e-mailadres in">
      `,
      confirmButtonText: "Verstuur",
      confirmButtonColor: "#c43402",
      showCancelButton: true,
      cancelButtonText: "Annuleren",
      cancelButtonColor: "#c43402",
      scrollbarPadding: false,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
        confirmButton: "custom-swal-button",
        cancelButton: "custom-swal-button",
      },
      preConfirm: async () => {
        const email = document.getElementById("forgot-password-email").value;

        if (!email) {
          Swal.showValidationMessage("Vul een geldig e-mailadres in.");
          return;
        }

        try {
          await requestPasswordReset(email);
          Swal.fire({
            icon: "success",
            title: "E-mail verzonden",
            text: "Er is een link voor het resetten van je wachtwoord naar je e-mail gestuurd.",
            confirmButtonColor: "#c43402",
            scrollbarPadding: false,
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Fout",
            text: "Er is iets misgegaan. Probeer het opnieuw.",
            confirmButtonColor: "#c43402",
            scrollbarPadding: false,
          });
        }
      },
    });
  };

return (
  <>
    <div className="page-name">
      <h2>Inloggen</h2>
    </div>
    <div className="page">
      <div className="password-reset-container">
        <form
          onSubmit={handleSubmit}
          className="reset-password-form"
          autoComplete="off"
        >
          {/* Onzichtbaar veld */}
          <input type="text" style={{ display: "none" }} aria-hidden="true" />

          <label htmlFor="email">Emailadres:</label>
          <input
            type="email"
            id="email"
            name={`fake_email_${Math.random()}`} // Random veldnaam
            autoComplete="new-email" // Voorkomt herkenning van eerder ingevulde emails
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password">Wachtwoord:</label>
          <input
            type="password"
            id="password"
            name={`fake_password_${Math.random()}`} // Random veldnaam
            autoComplete="new-password" // Voorkomt herkenning van eerder ingevulde wachtwoorden
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <div className="form-button-row">
            <button type="submit">Inloggen</button>
            <button
              type="button"
              className="forgot-password-button"
              onClick={handleForgotPassword}
            >
              Resetten
            </button>
          </div>
        </form>
      </div>
    </div>
  </>
);

};

export default LoginPage;
