import React, { useState, useEffect } from "react";
import Cookies from "js-cookie"; // Voor het beheren van cookies

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Controleer of de cookie nog niet is geaccepteerd
    const consent = Cookies.get("analytics_consent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set("analytics_consent", "true", { expires: 1 }); // Bewaar toestemming voor 1 jaar
    setIsVisible(false);
    // Initializeer Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag("consent", "update", {
      analytics_storage: "granted",
    });
  };

  const declineCookies = () => {
    Cookies.set("analytics_consent", "false", { expires: 365 }); // Bewaar weigering voor 1 jaar
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        background: "#333",
        color: "#fff",
        padding: "15px",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <p>
        We gebruiken cookies om je ervaring te verbeteren.{" "}
        <a href="/cookiebeleid" style={{ color: "#ffd700" }}>
          Lees ons cookiebeleid
        </a>
      </p>
      <br />
      <button
        onClick={acceptCookies}
        style={{
          marginRight: "10px",
          padding: "10px 15px",
          background: "#c43402",
          border: "none",
          cursor: "pointer",
        }}
      >
        Accepteren
      </button>
      <button
        onClick={declineCookies}
        style={{
          padding: "10px 15px",
          background: "#c43402",
          color: "#fff",
          border: "none",
          cursor: "pointer",
        }}
      >
        Weigeren
      </button>
    </div>
  );
};

export default CookieBanner;
