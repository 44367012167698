import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { registerUser, checkAvailability } from "../API/api";
import Swal from "sweetalert2";
import "../CSS/site.css";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useState({ username: "", email: "", password: "" });
  const [availability, setAvailability] = useState({ username: null });
  const [isInvite, setIsInvite] = useState(false); // Nieuw: Houd bij of het een uitnodiging is

  // Haal e-mailadres en inviteToken uit de URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams);
    const emailFromUrl = searchParams.get("email");
    const inviteToken = searchParams.get("token");

    if (emailFromUrl) {
      setForm((prevForm) => ({
        ...prevForm,
        email: emailFromUrl,
      }));
      setIsInvite(true); // Dit is een uitnodiging
    }
    if (inviteToken) {
      setForm((prevForm) => ({
        ...prevForm,
        inviteToken,
      }));
    }
  }, [location.search]);

  // Controleer beschikbaarheid van gebruikersnaam
  const debouncedCheckAvailability = debounce(async (name, value) => {
    try {
      const result = await checkAvailability({ [name]: value });
      setAvailability((prev) => ({
        ...prev,
        [name]: result.available ? "available" : "unavailable",
      }));
    } catch (error) {
      setAvailability((prev) => ({
        ...prev,
        [name]: "error",
      }));
    }
  }, 500);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));

    if (name === "username") {
      debouncedCheckAvailability(name, value);
    }
  };

  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerUser(form);
      Swal.fire(
        "Registratie Succesvol!",
        "Controleer uw e-mail om te bevestigen.",
        "success"
      );
    } catch (error) {
      Swal.showValidationMessage("Registratie mislukt. Probeer opnieuw.");
    }
  };

  const getBackgroundColor = (status) => {
    if (status === "available") return "#8bffae"; // Groen
    if (status === "unavailable") return "#ffa786"; // Rood
    if (status === "error") return "#ffcccb"; // Licht rood bij fout
    return ""; // Standaard
  };

  return (
    <>
      <div className="page-name">
        <h2>Registreren</h2>
      </div>
      <div className="page">
        <div className="password-reset-container">
          <form
            onSubmit={handleSubmit}
            className="reset-password-form"
            autoComplete="off"
          >
            <label htmlFor="Gebruikersnaam">Gebruikersnaam</label>
            <input
              id="Gebruikersnaam"
              type="text"
              name="username"
              autoComplete="new-username"
              onChange={handleChange}
              required
              style={{
                backgroundColor: getBackgroundColor(availability.username),
              }}
            />

            <label htmlFor="email">Emailadres</label>
            <input
              id="email"
              type="email"
              name="email"
              value={form.email}
              disabled={isInvite} // Maak onbewerkbaar als dit een uitnodiging is
              required
            />

            <label htmlFor="password">Wachtwoord</label>
            <input
              id="password"
              type="password"
              name="password"
              autoComplete="new-password"
              onChange={handleChange}
              required
            />

            <div className="form-button-row">
              <button type="submit">Registreren</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
