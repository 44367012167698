import React, { useEffect, useState, useContext } from "react";
import { fetchRecipes } from "../API/api";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import "../CSS/site.css";

const RecipeList = ({ searchTerm }) => {
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { user } = useContext(AuthContext); // Haal de ingelogde gebruiker op

  // console.log(user)

  // Extract author from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const author = queryParams.get("author");

  const getRecipes = async () => {
    setIsLoading(true);

    // Gebruik filters op basis van auteur of zoekterm
    const filters = author
      ? { author }
      : { search: searchTerm || "", limit: 10 };

    const data = await fetchRecipes(filters);

    // Filter alleen toegankelijke recepten: openbaar, van de gebruiker of gedeeld
    const accessibleRecipes = data.filter((recipe) => {
      const isOwner = recipe.author === user?.username;
      const isSharedWithUser =
        recipe.sharedWith &&
        recipe.sharedWith.some((sharedId) => sharedId === user?.id);
      return recipe.isPublic || isOwner || isSharedWithUser;
    });

    setRecipes(accessibleRecipes);
    setIsLoading(false);
  };

  useEffect(() => {
    getRecipes();
  }, [searchTerm, author]);

  // Client-side filtering op basis van zoektermen
  const filteredRecipes = recipes.filter((recipe) => {
    if (!searchTerm || author) return true;

    const searchTerms = searchTerm.toLowerCase().split(" ").filter(Boolean);
    return searchTerms.every((term) => {
      const matchesTitle = recipe.title
        ? recipe.title.toLowerCase().includes(term)
        : false;

      const matchesAuthor = recipe.author
        ? recipe.author.toLowerCase().includes(term)
        : false;

      const matchesCategory = recipe.category
        ? recipe.category.toLowerCase().includes(term)
        : false;

      const matchesIngredients = recipe.ingredients
        ? recipe.ingredients.some((ingredient) =>
            ingredient.toLowerCase().includes(term)
          )
        : false;

      const matchesTags = recipe.tags
        ? recipe.tags.some((tag) => tag.toLowerCase().includes(term))
        : false;

      // Controleer of de zoekterm overeenkomt met een van de velden
      return (
        matchesTitle ||
        matchesAuthor ||
        matchesCategory ||
        matchesIngredients ||
        matchesTags
      );
    });
  });

return (
  <div className="page">
    <div className="recipe-grid">
      {isLoading ? (
        <p>Recepten laden...</p>
      ) : filteredRecipes.length > 0 ? (
        filteredRecipes.map((recipe) => (
          <div className="recipe-card" key={recipe._id}>
            <Link to={`/recipe/${recipe._id}`}>
              <div className="recipe-card-image">
                {recipe.photo ? (
                  <img
                    src={recipe.photo}
                    alt={recipe.title}
                    className="recipe-image"
                  />
                ) : (
                  <div className="placeholder-image">
                    <p>Geen afbeelding</p>
                  </div>
                )}
              </div>
              <div className="divider-line"></div>
              <div className="recipe-card-content">
                <div className="recipecard-title">{recipe.title}</div>

                <div className="recipecard-items">
                  <div className="card-icons">
                    {recipe.author === user?.username ? (
                      <span
                        title="Jij bent de eigenaar van dit recept"
                        className="owner-icon"
                      >
                        <span className="material-icons">account_circle</span>
                      </span>
                    ) : recipe.isPublic ? (
                      <span
                        title="Dit recept is openbaar"
                        className="public-icon"
                      >
                        <span className="material-icons">public</span>
                      </span>
                    ) : recipe.sharedWith.some(
                        (sharedUser) => sharedUser.email === user?.email
                      ) ? (
                      <span
                        title="Dit recept is met jou gedeeld"
                        className="shared-icon"
                      >
                        <span className="material-icons">share</span>
                      </span>
                    ) : null}
                    <span
                      className="view-icon"
                    >
                      {/* <button className="material-icons">visibility</button> */}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))
      ) : (
        <p>Geen recepten gevonden.</p>
      )}
    </div>
  </div>
);

};

export default RecipeList;
