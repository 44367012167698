import React, { createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    username: localStorage.getItem("username"),
    token: localStorage.getItem("token"),
    id: localStorage.getItem("id"),
  });

  // Method to log the user in
  const login = (token, username, id) => {
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    localStorage.setItem("id", id);
    setUser({ token, username, id });
  };

  // Method to log the user out
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("id");
    setUser({ token: null, username: null });
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
