import React, { useContext } from "react";
import { AuthContext } from "../AuthContext";
import "../CSS/site.css";

const Footer = () => {
  const { user } = useContext(AuthContext);
  return (
    <div className="footer">
      <p>
        Deze website is momenteel in ontwikkeling, waardoor de reactiesnelheid
        soms wat lager kan zijn ~
        {user?.username
          ? ` je bent ingelogd als ${user.username}`
          : " je bent niet ingelogd"}
      </p>
    </div>
  );
};

export default Footer;
