// Home.js
import React, { useEffect, useState } from "react";
import { getRecentRecipes } from "../API/api";
import { Link } from "react-router-dom";
import "../CSS/site.css";

const Home = () => {
  const [recentRecipes, setRecentRecipes] = useState([]);
  useEffect(() => {
    const fetchRecentRecipes = async () => {
      const recipes = await getRecentRecipes();
      setRecentRecipes(recipes.slice(0, 5));
    };

    fetchRecentRecipes();
  }, []);

  return (
    <div className="page">
      <div className="intro-text">
        <h2 className="cursief">Welkom bij kookcollectief.nl</h2>
        <p className="startpage-text">
          Welkombij hét platform voor kookliefhebbers! Laat je inspireren, deel
          jouw favoriete recepten, en ontdek heerlijke nieuwe gerechten. Of je
          nu een beginnende kok bent of een doorgewinterde chef, hier vind je
          een uitgebreide collectie recepten voor elke smaak en gelegenheid.
          <br /> <br />
          Breng je culinaire creaties tot leven en deel ze met onze community.
          Dankzij onze nieuwe deeloptie kun je jouw recepten eenvoudig openbaar
          maken, of alleen delen met vrienden en familie. Voeg een persoonlijk
          bericht toe om je recepten nog specialer te maken. Zoek op
          ingrediënten, laat je verrassen door seizoensfavorieten, of ontdek
          verborgen pareltjes. Samen maken we koken niet alleen leuker, maar ook
          creatiever en toegankelijker.
          <br /> <br />
          <bold>
            Sluit je vandaag nog aan bij onze groeiende community, voeg je eigen
            recepten toe, deel ze met wie jij wilt, en ervaar alles wat de
            wereld van koken te bieden heeft!
          </bold>
        </p>
        {/* <div className="recent-recipes">
          <div className="">
            <div className="recipe-grid">
              {recentRecipes.length > 0 ? (
                recentRecipes.map((recipe) => (
                  <div className="recipe-card" key={recipe._id}>
                    <Link to={`/recipe/${recipe._id}`}>
                      <div className="recipe-card-image">
                        {recipe.photo ? (
                          <img
                            src={recipe.photo}
                            alt={recipe.title}
                            className="recipe-image"
                          />
                        ) : (
                          <div className="placeholder-image">
                            <p>Geen afbeelding</p>
                          </div>
                        )}
                      </div>
                      <div className="divider-line"></div>
                      <div className="recipe-card-content">
                        <div className="recipecard-title">{recipe.title}</div>
                        <button className="open-recipe-button">Bekijken</button>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p>Geen recepten gevonden.</p>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
