// Menu.js
import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SearchIcon } from "./PAGES/Icons";
import logo from "../src/IMAGES/pan.png";
import { AuthContext } from "./AuthContext";
import {
  loginUser,
  registerUser,
  checkAvailability,
  requestPasswordReset,
} from "./API/api";
import "../src/CSS/menu.css";

const MySwal = withReactContent(Swal);

const Menu = ({ onSearchChange }) => {
  const { user, logout, login } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    onSearchChange(searchTerm);
    setSearchTerm("");
    if (location.pathname !== "/recipes") {
      navigate("/recipes");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handleLogout = () => {
    logout();
    Swal.fire({
      icon: "success",
      html: `<div>Je bent uitgelogd!</div>`,
      confirmButtonColor: "#c43402",
      timer: 2500,
      showConfirmButton: false,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
      },
    });
    navigate("/");
  };

  const goToRecipes = () => {
    setSearchTerm(""); // Reset de lokale zoekterm
    onSearchChange(""); // Reset de zoekterm in de App-component
    navigate("/recipes"); // Navigeer naar de receptenpagina
  };

  const goToUpload = () => navigate("/upload");

  // Function to open the SweetAlert2 login form
  const openLoginPopup = () => {
    MySwal.fire({
      title: "Inloggen",
      html: `
    <form id="login-form" autocomplete="off">
      <!-- Onzichtbaar veld -->
      <input type="text" style="display:none" aria-hidden="true">
      <input 
        type="email" 
        id="email" 
        name="fake_email_${Math.random()}" 
        autocomplete="new-email" 
        class="swal2-input" 
        placeholder="Emailadres"
      >
      <input 
        type="password" 
        id="password" 
        name="fake_password_${Math.random()}" 
        autocomplete="new-password" 
        class="swal2-input" 
        placeholder="Wachtwoord"
      >
      <p style="margin-top: 10px; text-align: center;">
        <a id="forgot-password-link" style="cursor: pointer; color: #007bff; text-decoration: underline;">
          Wachtwoord vergeten?
        </a>
      </p>
    </form>
  `,
      focusConfirm: false,
      confirmButtonText: "Oke",
      confirmButtonColor: "#c43402",
      scrollbarPadding: false,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
        confirmButton: "custom-swal-button",
      },
      didOpen: () => {
        // Voeg event listener toe aan de "Wachtwoord vergeten" link
        const forgotPasswordLink = document.getElementById(
          "forgot-password-link"
        );
        forgotPasswordLink.addEventListener("click", () => {
          MySwal.fire({
            title: "Reset Aanvragen",
            html: `
      <form id="forgot-password-form" autocomplete="off">
        <input type="email" id="forgot-email" class="swal2-input" placeholder="Voer uw emailadres in">
      </form>
    `,
            focusConfirm: false,
            confirmButtonText: "Verstuur",
            confirmButtonColor: "#c43402",
            showCancelButton: true,
            cancelButtonText: "Annuleren",
            cancelButtonColor: "#c43402",
            scrollbarPadding: false, // Voorkomt verspringen van de pagina
            customClass: {
              title: "custom-swal-title",
              htmlContainer: "custom-swal-html",
              confirmButton: "custom-swal-button",
              cancelButton: "custom-swal-button",
            },
            preConfirm: async () => {
              const email = document.getElementById("forgot-email").value;

              if (!email) {
                Swal.showValidationMessage("Vul uw emailadres in.");
                return;
              }

              try {
                // Stuur verzoek naar API om wachtwoord reset te initiëren
                await requestPasswordReset(email); // Verander dit naar jouw API-functie
                Swal.fire({
                  title: "E-mail verzonden",
                  text: "Er is een link voor het resetten van uw wachtwoord naar uw e-mail gestuurd.",
                  icon: "success",
                  confirmButtonColor: "#c43402",
                  scrollbarPadding: false, // Zorg ervoor dat deze consistent blijft
                });
              } catch (error) {
                Swal.fire({
                  title: "Fout",
                  text: "Er is iets misgegaan. Probeer het opnieuw.",
                  icon: "error",
                  confirmButtonColor: "#c43402",
                  scrollbarPadding: false, // Zorg ervoor dat deze consistent blijft
                });
              }
            },
          });
        });
      },
      preConfirm: async () => {
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;

        if (!email || !password) {
          Swal.showValidationMessage(
            "Vul zowel een e-mailadres als een wachtwoord in."
          );
          return;
        }

        try {
          const { token, username, id } = await loginUser({ email, password });
          login(token, username, id);

          const params = new URLSearchParams(location.search);
          const redirectUrl = params.get("redirect") || "/";
          navigate(redirectUrl);

          Swal.fire({
            icon: "success",
            html: `<div>Je bent ingelogd!</div>`,
            confirmButtonText: "Oke",
            confirmButtonColor: "#c43402",
            showConfirmButton: false,
            timer: 2500,
            scrollbarPadding: false,
            customClass: {
              title: "custom-swal-title",
              htmlContainer: "custom-swal-html",
            },
          });
        } catch (error) {
          Swal.showValidationMessage(
            "Inloggen mislukt. Controleer uw gegevens."
          );
        }
      },
    });
  };

  // Function to open the SweetAlert2 registration form
  const openRegisterPopup = () => {
    MySwal.fire({
      title: "Registreren",
      html: `
      <input type="text" id="username" class="swal2-input" autocomplete="off" placeholder="Gebruikersnaam">
      <input type="email" id="email" class="swal2-input" autocomplete="off" placeholder="Emailadres">
      <input type="password" id="password" autocomplete="off" class="swal2-input" placeholder="Wachtwoord">
    `,
      focusConfirm: false,
      confirmButtonText: "Registreren",
      confirmButtonColor: "#c43402",
      cancelButtonText: "Annuleren",
      cancelButtonColor: "#c43402",
      showCancelButton: true,
      scrollbarPadding: false,
      customClass: {
        title: "custom-swal-title",
        htmlContainer: "custom-swal-html",
        confirmButton: "custom-swal-button",
        cancelButton: "custom-swal-button",
      },

      didOpen: () => {
        document
          .getElementById("username")
          .addEventListener("input", async (e) => {
            const username = e.target.value;
            if (username.trim() === "") {
              e.target.style.backgroundColor = ""; // Reset achtergrondkleur als het veld leeg is
              return;
            }
            const result = await checkAvailability({ username });
            e.target.style.backgroundColor = result.available
              ? "#8bffae"
              : "#ffa786"; // Groen als beschikbaar, rood als bezet
          });

        document
          .getElementById("email")
          .addEventListener("input", async (e) => {
            const email = e.target.value;
            if (email.trim() === "") {
              e.target.style.backgroundColor = ""; // Reset achtergrondkleur als het veld leeg is
              return;
            }
            const result = await checkAvailability({ email });
            e.target.style.backgroundColor = result.available
              ? "#8bffae"
              : "#ffa786"; // Groen als beschikbaar, rood als bezet
          });
      },

      preConfirm: async () => {
        const username = document.getElementById("username").value;
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;

        if (!username || !email || !password) {
          Swal.showValidationMessage("Vul alle velden in.");
          return;
        }

        try {
          await registerUser({ username, email, password });
          Swal.fire(
            "Registratie Succesvol!",
            "Controleer uw e-mail om te bevestigen.",
            "success"
          );
        } catch (error) {
          Swal.showValidationMessage("Registratie mislukt. Probeer opnieuw.");
        }
      },
    });
  };

  return (
    <div className="menu">
      <div className="logo-menu">
        <img src={logo} alt="Logo" />
        <span className="site-name" onClick={() => navigate("/")}>
          kookcollectief.nl
        </span>
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Zoek naar recepten..."
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          className="search-input-menu"
        />
        <button
          onClick={handleSearchSubmit}
          className="search-button"
          aria-label="Zoeken"
        >
          <SearchIcon />
        </button>
      </div>
      <div className="menu-buttons-container">
        <button onClick={goToRecipes}>Recepten</button>
        {user.username ? (
          <>
            <button onClick={goToUpload}>Toevoegen</button>
            <button onClick={handleLogout}>Uitloggen</button>
          </>
        ) : (
          <>
            <button onClick={openRegisterPopup}>Registreren</button>
            <button onClick={openLoginPopup}>Inloggen</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Menu;
