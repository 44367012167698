import React from "react";

const CookiePolicy = () => {
  return (
    <div className="page">
      <div className="cookie-container">
        <h1 className="text-3xl font-bold mb-4">Cookiebeleid</h1>
        <p className="mb-4">
          Laatst bijgewerkt: <strong>21/11/2024</strong>
        </p>
        <br />
        <p className="mb-6">
          Welkom bij <strong>kookcollectief.nl</strong>! <br />
          Dit cookiebeleid legt uit wat cookies zijn, hoe wij ze gebruiken en
          hoe jij je cookie-instellingen kunt beheren. We willen transparant
          zijn over de gegevens die we verzamelen en hoe we deze gebruiken om
          jouw ervaring te verbeteren.
        </p>
        <br />
        <h2 className="text-2xl font-bold mb-4">Wat zijn cookies?</h2>
        <p className="mb-6">
          Cookies zijn kleine tekstbestanden die worden opgeslagen op jouw
          apparaat (computer, tablet of smartphone) wanneer je onze website
          bezoekt. Ze worden gebruikt om informatie te verzamelen over jouw
          surfgedrag en voorkeuren, zodat we de functionaliteit en prestaties
          van onze website kunnen verbeteren.
        </p>
        <br />
        <h2 className="text-2xl font-bold mb-4">Welke cookies gebruiken we?</h2>
        <p className="mb-4">Wij gebruiken de volgende soorten cookies:</p>
        <br />
        <h3 className="text-xl font-semibold mb-2">
          1. Strikt noodzakelijke cookies
        </h3>
        <p className="mb-6">
          Deze cookies zijn essentieel voor de werking van onze website. Ze
          zorgen ervoor dat de site goed functioneert en dat je toegang hebt tot
          beveiligde gedeeltes van de site. Voor deze cookies is geen
          toestemming nodig.
        </p>
        <br />
        <h3 className="text-xl font-semibold mb-2">2. Analytische cookies</h3>
        <p className="mb-6">
          We gebruiken <strong>Google Analytics</strong> om inzicht te krijgen
          in hoe onze website wordt gebruikt. Deze cookies helpen ons te
          begrijpen welke pagina's populair zijn, hoe lang bezoekers op de site
          blijven, en welke interacties plaatsvinden. Met deze informatie kunnen
          we onze website verbeteren en beter afstemmen op jouw behoeften.
        </p>
        <ul className="list-disc ml-6 mb-6">
          <li>Bezochte pagina's en de tijd die je daarop doorbrengt.</li>
          <li>
            De bron van jouw bezoek (bijvoorbeeld via een zoekmachine of een
            link).
          </li>
          <li>
            Anonieme informatie over het apparaat en de browser die je gebruikt.
          </li>
        </ul>
        <p className="mb-6">We hebben Google Analytics zo ingesteld dat:</p>
        <ul className="list-disc ml-6 mb-6">
          <li>
            Jouw IP-adres wordt geanonimiseerd (de laatste cijfers worden
            verborgen).
          </li>
          <li>Er geen gegevens worden gedeeld met derden.</li>
        </ul>
        <br />
        <h3 className="text-xl font-semibold mb-2">
          3. Functionele en voorkeurencookies
        </h3>
        <p className="mb-6">
          Momenteel maken we geen gebruik van functionele cookies. Mochten we in
          de toekomst voorkeuren zoals taalinstellingen opslaan, dan zullen we
          dit hier vermelden.
        </p>
        <br />
        <h3 className="text-xl font-semibold mb-2">
          4. Tracking- en advertentiecookies
        </h3>
        <p className="mb-6">
          Wij gebruiken geen cookies voor advertenties of tracking.
        </p>
        <br />
        <h2 className="text-2xl font-bold mb-4">
          Hoe kun je cookies beheren of uitschakelen?
        </h2>
        <p className="mb-6">
          Bij jouw eerste bezoek aan onze website vragen we om toestemming voor
          het gebruik van analytische cookies. Je kunt jouw voorkeuren op elk
          moment wijzigen of cookies volledig uitschakelen via de instellingen
          van jouw browser.
        </p>
        <p className="mb-6">
          Hier zijn links naar handleidingen voor populaire browsers:
        </p>
        <ul className="list-disc ml-6 mb-6">
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=nl"
              className="text-blue-600 underline"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/nl/kb/cookies-in-en-uitschakelen-websites-voorkeuren"
              className="text-blue-600 underline"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/nl-nl/guide/safari/sfri11471/mac"
              className="text-blue-600 underline"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/nl-nl/microsoft-edge/browse-inprivate-in-microsoft-edge-0a5e5b3a-5e78-407b-9a3c-1d609dcbdc2a"
              className="text-blue-600 underline"
            >
              Microsoft Edge
            </a>
          </li>
        </ul>

        {/* <h2 className="text-2xl font-bold mb-4">Jouw privacy</h2>
        <p className="mb-6">
          Voor meer informatie over hoe wij met jouw gegevens omgaan, kun je ons{" "}
          <a href="/privacybeleid" className="text-blue-600 underline">
            Privacybeleid
          </a>{" "}
          raadplegen.
        </p> */}
        <br />
        <h2 className="text-2xl font-bold mb-4">Contact</h2>
        <p>
          Als je vragen hebt over ons cookiebeleid, kun je contact met ons
          opnemen via:
        </p>
        <ul className="list-disc ml-6 mb-6">
          <li>
            <strong>E-mail:</strong>{" "}
            <a
              href="info@kookcollectief.nl"
              className="text-blue-600 underline"
            >
              info@kookcollectief.nl
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CookiePolicy;
