import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from "./Menu";
import Recipes from "./PAGES/RecipeList";
import RecipeUpload from "./PAGES/RecipeUpload";
import RecipeDetails from "./PAGES/RecipeDetails";
import RecipeEdit from "./PAGES/RecipeEdit";
import Home from "./PAGES/Start";
import ConfirmAccount from "./PAGES/ConfirmAccount";
import NotFound from "./PAGES/NotFound";
import ResetPasswordPage from "./PAGES/ResetPasswordForm";
import LoginPage from "./PAGES/LoginPage";
import Footer from "./PAGES/footer";
import ReactGA from "react-ga4";
import Register from "./PAGES/Register";
import AccessDenied from "./PAGES/AccessDenied";
import CookieBanner from "./CookieBanner";
import Cookies from "js-cookie";
import CookiePolicy from "./beleid";
import "./CSS/site.css";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    // Controleer of toestemming is gegeven
    const consent = Cookies.get("analytics_consent");
    if (consent === "true") {
      initializeAnalytics(); // Start Analytics alleen met toestemming
    }
  }, []);

const initializeAnalytics = () => {
  ReactGA.initialize("G-3LXCE0Z3N3", {
    gaOptions: {
      anonymizeIp: true,
      allowAdFeatures: false, // Geen advertentiefuncties
    },
  });
  ReactGA.send("pageview");
};

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    // Controleer toestemming en start Analytics indien toegestaan
    const consent = Cookies.get("analytics_consent");
    if (consent === "true") {
      initializeAnalytics(); // Start Google Analytics
    }
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Router>
      <div className="App">
        <CookieBanner />
        <Menu
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          onSearchChange={setSearchTerm}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth/confirm/:token" element={<ConfirmAccount />} />
          <Route
            path="/recipes"
            element={<Recipes searchTerm={searchTerm} />}
          />
          <Route path="/upload" element={<RecipeUpload />} />
          <Route path="/recipe/:id" element={<RecipeDetails />} />
          <Route path="/edit/:id" element={<RecipeEdit />} />
          <Route
            path="/auth/reset-password/:token"
            element={<ResetPasswordPage />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/cookiebeleid" element={<CookiePolicy />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
